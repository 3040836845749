import React from "react";
import styles from "./LogoQuote.module.css";
import Logo from "../assets/Images/logo_section.png";
function LogoQuote() {
  return (
    <section className={styles.Main}>
      <div className={styles.Logo}>
        <img src={Logo} alt="" />
      </div>
      <div className={styles.Quote}>
        <p>
          <span>
            We believe in the art of ‘Silent luxury’ and the concept of ‘Less is
            more’.
          </span>
          <br/>
          <strong>
            Every interior space we design is unique, as it reflects your
            personality along with the essence of TGR Gawri Constuctions.
          </strong>
        </p>
      </div>
    </section>
  );
}

export default LogoQuote;
