import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import { Routes, Route, Link } from "react-router-dom";
import Projects from "./pages/Projects";
import { useEffect } from "react";
import PrivacyTerms from "./pages/PrivacyTerms";

function App() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="App">
      <button
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        style={{
          position: "fixed",
          padding: "0.5rem 1rem",
          fontSize: "10px",
          bottom: "30px",
          right: "30px",
          backgroundColor: "#1A1A1B",
          color: "#fff",
          textAlign: "center",
          zIndex: "1000",
          cursor: "pointer",
        }}
      >
        Scroll to top
      </button>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/PrivacyPolicyandTOS" element={<PrivacyTerms/>}/>
      </Routes>
    </div>
  );
}

export default App;
