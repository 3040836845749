import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import Backdrop from "./Backdrop";

const LightBox = ({ children, src, alt, Wrapper = "div", zIndex = 500 }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper onClick={toggleIsOpen}>
       <Backdrop show={isOpen}/>
      {children}
      {isOpen ? (
         
        <div
          onClick={toggleIsOpen}
          style={{
            position: "fixed",
            top: isMobile ? "25vh" : "25vh",
            left: isMobile ? "5vw" : "25vw",
            height: "auto",
            width: isMobile ? "90vw" : "50vw",
            backgroundColor: "rgba(0,0,0,0.7)",
            cursor: "pointer",
            zIndex:"500",
          }}
        >

          <button
            style={{
              height: "20px",
              width: "100px",
              border: "5px white bold",
              display: "flex",
              justifyContent: "space-evenly",
              float: "right",
            }}
            onClick={toggleIsOpen}
          >
            <strong>X</strong>
            Close
          </button>

          <img
            src={src}
            alt={alt}
            style={{
              height: "auto",
              width: "100%",
            }}
          />
        </div>
      ) : null}
    </Wrapper>
  );
};

export default LightBox;
