import React, { useState } from "react";
import Card from "./Card";
import SimpleImageSlider from "react-simple-image-slider";
import styles from "./Stats.module.css";
import useWindowDimension from "../hooks/useWindowDimension";
const images = [
  require("../assets/Images/imageSlider/Image1.jpeg"),
  require("../assets/Images/imageSlider/Image2.jpeg"),
  require("../assets/Images/imageSlider/Image3.jpeg"),
  require("../assets/Images/imageSlider/Image4.jpeg"),
];

function Stats() {
  const { height, width } = useWindowDimension();

  return (
    <section className={styles.stats}>
      <Card
        number1={148}
        number2={126}
        type1={"Projects Completed"}
        type2={"Satisfied Clients"}
        desc1={
          "Commitment to timelines with a focussed approach, accountability and teamwork assist in a well timed handover of our sites."
        }
        desc2={
          "Our client testimonials add validation for our services, the attention to detail and quality of products we offer. "
        }
        bgcolor={"#dbdbdb"}
        textcolor={"#414141"}
      />
      {/* <Card /> */}
      <div className={styles.imageSlider}>
        <SimpleImageSlider
          width={width < 990 ? 0.89 * width : 380}
          height={600}
          images={images}
          showBullets={false}
          showNavs={false}
          loop={true}
          autoPlay={true}
        />
      </div>

      {/* img */}
      <Card
        number1={37}
        number2={12}
        type1={"Industry Experience"}
        type2={"Pan India Locations"}
        desc1={
          "With over 37 years of experience, we have been able to provide special advice in the planning, co-ordination and technical aspect of the projects we do."
        }
        desc2={
          "Our Pan India experience helps us manage material and human resources throughout."
        }
        bgcolor={"#414141"}
        textcolor={"#DBDBDB"}
      />
    </section>
  );
}

export default Stats;
