export const Data = [
  {
    imageURL: require("../assets/Images/testimonials/testimonials1.png"),
  },
  {
    imageURL: require("../assets/Images/testimonials/testimonials2.png"),
  },
  {
    imageURL: require("../assets/Images/testimonials/testimonials3.png"),
  },
  {
    imageURL: require("../assets/Images/testimonials/testimonials4.png"),
  },
  {
    imageURL: require("../assets/Images/testimonials/testimonials5.png"),
  },
  {
    imageURL: require("../assets/Images/testimonials/testimonials6.png"),
  },
];
