import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ProjectsDisplay from "../components/ProjectsDisplay";


function Projects() {
  return (
    <div>
      <ProjectsDisplay/>
      <Footer />
    </div>
  );
}

export default Projects;
