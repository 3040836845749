import { Grid, Paper, styled } from "@mui/material";
import React, { useState } from "react";
import styles from "./ProjectsDisplay.module.css";
import { Projects } from "../data/Projects";
import { ImgOverlay } from "./Overlay";
import FsLightbox from "fslightbox-react";
import LightBox from "./Lightbox";
import Logo from "../assets/Images/logo_section.png";
import { Link } from "react-router-dom";

function ProjectsDisplay() {
  const [toggler, setToggler] = useState(false);
  return (
    <section className={styles.Display}>
      
      <div className={styles.Logo}>
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <h2>PROJECTS</h2>
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 1, sm: 8, md: 12 }}
        width="70vw"
        margin="0px"
        padding="0 15vw"
        paddingBottom="100px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {Projects.map((image, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <LightBox src={image.imageUrl}>
              <ImgOverlay
                imgSrc={image.imageUrl}
                width="100%"
                height="100%"
                fSize="18px"
              >
                Name: {image.name}
                <br />
                Scope: {image.scope}
                <br />
                Timeline: {image.timeline}
                <br />
                Location: {image.location}
              </ImgOverlay>
            </LightBox>
          </Grid>
        ))}
      </Grid>
    </section>
  );
}

export default ProjectsDisplay;
