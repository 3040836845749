import React from "react";
import Header from "../components/Header";
import ImageGrid from "../components/ImageGrid";
import LogoQuote from "../components/LogoQuote";
import MeetOurTeam from "../components/MeetOurTeam";
import OurPortfolio from "../components/OurPortfolio";
import Stats from "../components/Stats";
import WhatWeOffer from "../components/WhatWeOffer";
import Whyus from "../components/Whyus";
import Testimonials from "../components/testimonials";
import Testimonialphoto from "../components/Testimonialphoto";
import Footer from "../components/Footer";
function Home() {
  return (
    <div>
      <Header />
      <LogoQuote />
      <OurPortfolio />
      <Whyus />
      <Stats />
      <WhatWeOffer />
      <ImageGrid />
      <MeetOurTeam />
      <Testimonials />
      <Testimonialphoto />
      <Footer />
    </div>
  );
}

export default Home;
