import React from "react";
import Logo from "../assets/Images/logo_section.png";
import { Link } from "react-router-dom";
import styles from "../components/privacy.module.css";
function PrivacyTerms() {
  return (
    <div className={styles.container}>
      <div className={styles.Logo}>
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className={styles.pp}>
        <h2>Privacy Policy</h2>
        <p>
          Last updated: 16<sup>th</sup> July, 2022
        </p>
        <br />
        <p>
          TGR GAWRI CONSTRUCTIONS LLP (“us”, “we”, or “our”) operates
          https://tgrgawriconstructions.com (the “Site”). This page informs you
          of our policies regarding the collection, use and disclosure of
          Personal Information we receive from users of the Site.
        </p>
        <br />
        <p>
          We use your Personal Information only for providing and improving the
          Site. By using the Site, you agree to the collection and use of
          information in accordance with this policy.
        </p>
        <h4>Information Collection and Use</h4>
        <p>
          While using our Site, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to your name (“Personal Information”).
        </p>
        <h4>Log Data</h4>{" "}
        <p>
          Like many site operators, we collect information that your browser
          sends whenever you visit our Site (“Log Data”).
        </p>
        <br />
        <p>
          This Log Data may include information such as your computer’s Internet
          Protocol (“IP”) address, browser type, browser version, the pages of
          our Site that you visit, the time and date of your visit, the time
          spent on those pages and other statistics.
        </p>
        <br />{" "}
        <p>
          In addition, we may use third party services such as Google Analytics
          that collect, monitor and analyse user behavior.
        </p>
        <h4> Communications</h4>
        <p>
          We may use your Personal Information to contact you with newsletters,
          marketing or promotional materials and other information.
        </p>{" "}
        <h4>Cookies</h4>{" "}
        <p>
          Cookies are files with small amount of data, which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          web site and stored on your computer’s hard drive.
        </p>{" "}
        <br />
        <p>
          Like many sites, we use “cookies” to collect information. You can
          instruct your browser to refuse all cookies or to indicate when a
          cookie is being sent. However, if you do not accept cookies, you may
          not be able to use some portions of our Site.{" "}
        </p>
        <h4>Security</h4>{" "}
        <p>
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage, is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security.
        </p>
        <h4>Changes to this Privacy Policy</h4>
        <p>
          {" "}
          This Privacy Policy is effective as of 15th December 2020 and will
          remain in effect except with respect to any changes in its provisions
          in the future, which will be in effect immediately after being posted
          on this page.
        </p>
        <br />
        <p>
          {" "}
          We reserve the right to update or change our Privacy Policy at any
          time and you should check this Privacy Policy periodically. Your
          continued use of the Service after we post any modifications to the
          Privacy Policy on this page will constitute your acknowledgment of the
          modifications and your consent to abide and be bound by the modified
          Privacy Policy.
        </p>
        <br />
        <p>
          {" "}
          If we make any material changes to this Privacy Policy, we will notify
          you either through the email address you have provided us, or by
          placing a prominent notice on our website.
        </p>{" "}
        <h4>Contact Us</h4>
        <p>
          {" "}
          If you have any questions about this Privacy Policy, please contact
          us.
        </p>
      </div>
      <div className={styles.pp}>
        <h2>Terms of Service</h2>
        <p>
          Last updated: 16<sup>th</sup> July, 2022
        </p>
        <p>
          Please read these Terms of Service (“Terms”, “Terms of Service”)
          carefully before using the https://thegreyyroom.com website operated
          by TGR GAWRI CONSTRUCTIONS LLP (“us”, “we”, or “our”).
        </p>
        <p>
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service.
        </p>
        <h4>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service. Termination
        </h4>
        <h4>Termination</h4>
        <p>
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if you breach the Terms.
        </p>
        <p>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
        <h4>Links to Other Web Sites</h4>
        <p>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by TGR GAWRI CONSTRUCTIONS LLP.</p>
          <p> TGR
          GAWRI CONSTRUCTIONS LLP has no control over, and assumes no
          responsibility for, the content, privacy policies, or practices of any
          third party web sites or services.{" "}
        </p>{" "}
        <p>You further acknowledge and agree that TGR GAWRI CONSTRUCTIONS LLP shall
        not be responsible or liable, directly or indirectly, for any damage or
        loss caused or alleged to be caused by or in connection with use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services.</p> <h4>Changes</h4> <p>We reserve the right, at our
        sole discretion, to modify or replace these Terms at any time. What
        constitutes a material change will be determined at our sole discretion.</p>
        <h4>Contact Us</h4> <p>If you have any questions about this Privacy Policy, please
        contact us.</p>
      </div>
      <Link to="/">
        <div className={styles.button_85}>
        Go to Home
      </div>
      </Link>
    </div>
  );
}

export default PrivacyTerms;
