import React from "react";
import styles from "./TestimonialCard.module.css";
function TestimonialCard(props) {
  return (
    <div className={styles.card}>
      <img src={props.image} alt="" />
    </div>
  );
}

export default TestimonialCard;
