import React from "react";
import styles from "./whyus.module.css";
function Whyus() {
  return (
    <section id="whyus" className={styles.whyus}>
      <div className={styles.heading}>
        <h4>WHY US</h4>
      </div>
      <div className={styles.para}>
        <p>
          <strong>Gawri Constructions</strong> is a ‘design and build’ turnkey
          company for multinational corporations since 1986. Our management
          believes to solve the following – <br />
          <strong>Needs:</strong> On time delivery of projects to ensure ease of
          capex management for our clients. <br />
          <strong>Desires:</strong> Quality driven site execution as per
          international standards.
          <br />
          <strong>Problems:</strong> Flexible working hours for minimum
          disturbance of client’s daily activities.
        </p>
      </div>
    </section>
  );
}

export default Whyus;
