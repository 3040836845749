import React from "react";
import styles from "./whatweoffer.module.css";
function Testimonials() {
  return (
    <section className={styles.whyus}>
      <div className={styles.heading}>
        <h4>HAPPY CLIENTS</h4>
      </div>
      
    </section>
  );
}

export default Testimonials;