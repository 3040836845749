import React from "react";
import TestimonialCard from "./TestimonialCard";
import styles from "./Testimonialphoto.module.css";
import { Data } from "../data/Testimonials";
import { isMobile } from "react-device-detect";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

function Testimonialphoto() {
  const responsive = {
    0: {
      items: 1,
    },
    1024: {
      items: 2,
    },
  };

  const items = [<TestimonialCard />, <TestimonialCard />, <TestimonialCard />];
  const items1 = Data.map((d, index) => <TestimonialCard image={d.imageURL} />);
  return (
    <section className={styles.Tphoto}>
      <AliceCarousel
        autoWidth={true}
        mouseTracking
        autoPlay={isMobile ? true : false}
        autoPlayInterval={1500}
        animationType="fadeout"
        infinite
        responsive={responsive}
        disableButtonsControls={true}
        items={items1}
      />
    </section>
  );
}

export default Testimonialphoto;
