import Backdrop from "./Backdrop.js";
import React from "react";
import styles from "./SideBar.module.css";
import { Link as Link1, animateScroll as scroll } from "react-scroll";
function SideBar(props) {
  let attachedClasses = [styles.sideBar, styles.Close];
  if (props.open) {
    attachedClasses = [styles.sideBar, styles.Open];
  }
  return (
    <div className={attachedClasses.join(" ")}>
      <button onClick={props.close}>X</button>
      <div className={styles.company}>
        <span> TGR Gawri Constructions</span>
      </div>
      <div className={styles.options}>
        <div>
          <Link1 to="whyus" smooth={true} onClick={props.close}>
            WHY US
          </Link1>
        </div>
        <div>
          <Link1 to="whatweoffer" smooth={true} onClick={props.close}>
            WHAT WE OFFER
          </Link1>
        </div>
        <div>
          <Link1 to="meetourteam" smooth={true} onClick={props.close}>
            MEET OUR TEAM
          </Link1>
        </div>
        <div>
          <a
            href="https://drive.google.com/file/d/1lGv1m4daz3wx9TI2QghwUxSkNv9pvyFF/view?usp=sharing"
            target="_blank"
          >
            DOWNLOAD OUR PORTFOLIO
          </a>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
