import React from "react";
import CountUp from "react-countup";
import styles from "./Card.module.css";
function Card(props) {
  return (
    <div
      className={styles.card}
      style={{
        color: `${props.textcolor}`,
        backgroundColor: `${props.bgcolor}`,
      }}
    >
      <span>
        <CountUp end={props.number1} duration={2} />+
      </span>
      <div>{props.type1}</div>
      <div className={styles.para}>
        <p>{props.desc1}</p>
      </div>
      <span>
        <CountUp end={props.number2} duration={2} />+
      </span>
      <div>{props.type2}</div>
      <div className={styles.para}>
        <p>{props.desc2}</p>
      </div>
    </div>
  );
}

export default Card;
