import { Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import styles from "./OurPortfolio.module.css";
import AMEX from "../assets/Images/CompanyLogo/AmericanExpress.png";
import ThreeM from "../assets/Images/CompanyLogo/3M.jpeg";
import SIEMENS from "../assets/Images/CompanyLogo/SIEMENS.jpeg";
import SONY from "../assets/Images/CompanyLogo/SONY.png";
import HP from "../assets/Images/CompanyLogo/HP.jpeg";
import TWB from "../assets/Images/CompanyLogo/WORLDBANK.jpeg";
import GILLETTE from "../assets/Images/CompanyLogo/GILLETTE.jpeg";
import FORTUM from "../assets/Images/CompanyLogo/FORTUM.jpeg";
import GSK from "../assets/Images/CompanyLogo/GSK.png";
import CE from "../assets/Images/CompanyLogo/CANADIAN EMBASSY.jpeg";
import UNDP from "../assets/Images/CompanyLogo/UNDP.jpeg";
import UNICEF from "../assets/Images/CompanyLogo/UNICEF.png";
import WHO from "../assets/Images/CompanyLogo/WHO.jpeg";
import DABUR from "../assets/Images/CompanyLogo/DABUR.png";
import RS from "../assets/Images/CompanyLogo/RS.jpeg";
import DRW from "../assets/Images/CompanyLogo/DRWILLMAR.png";
function OurPortfolio() {
  const Images = [
    CE,
    UNDP,
    TWB,
    UNICEF,
    WHO,
    FORTUM,
    DABUR,
    SONY,
    AMEX,
    RS,
    GSK,
    DRW,
  ];
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    height: "100px",
    width: "100%",
    color: theme.palette.text.secondary,
    boxShadow: "1px 0.8px 0.5px 0px rgba(0,0,0,0.2)",
  }));

  return (
    <div>
      <section className={styles.portfolio}>
        <div className={styles.heading}>
          <h4>OUR PORTFOLIO</h4>
        </div>
      </section>
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 1, sm: 8, md: 12 }}
        width="70vw"
        margin="30px 0px"
        padding="0 15vw"
        paddingBottom="100px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {Images.map((image, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <Item>
              <img
                height={90}
                src={image}
                alt=""
                style={{ maxWidth: "100%" }}
              />
            </Item>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default OurPortfolio;
