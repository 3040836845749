import React, { useState } from "react";
import styles from "./header.module.css";
import placeIcon from "../assets/Images/place.svg";
import MenuIcon from "@mui/icons-material/Menu";
import useWindowDimension from "../hooks/useWindowDimension";
import SideBar from "./SideBar";
import Backdrop from "./Backdrop";
import Logo from "../assets/Images/logo_section.png";
import { Link } from "react-router-dom";
import { Link as Link1, animateScroll as scroll } from "react-scroll";

function Header() {
  const { height, width } = useWindowDimension();
  //STATE MANAGEMENT
  const [menu, setMenu] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);

  //FUNCTION
  if (width <= 768 && menu === false) {
    setMenu(true);
  }
  if (width > 768 && menu) {
    setMenu(false);
  }

  const SideBarHandler = () => {
    setSideMenu(true);
  };
  const CloseEventListner = () => {
    setSideMenu(false);
  };
  // console.log("MENU ", menu);

  return (
    <div className={styles.Background}>
      <SideBar open={sideMenu} close={CloseEventListner} />
      <Backdrop show={sideMenu} clicked={CloseEventListner} />

      
      <div className={styles.Middle}>
        <div className={styles.burger}>
          {menu && <MenuIcon onClick={SideBarHandler} />}
        </div>
        <div className={styles.Navbar}>
        
          <nav className={styles.List}>
      
            <div className={styles.Logo}>
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <ul>
              <Link1 to="whyus" smooth={true}>
                WHY US
              </Link1>

              <Link1 to="whatweoffer" smooth={true}>
                {/* <span>WHAT WE OFFER</span> */}
                WHAT WE OFFER
              </Link1>
              <Link1 to="meetourteam" smooth={true}>
                {/* <span>MEET OUR TEAM</span> */}
                MEET OUR TEAM
              </Link1>
              <a
                href="https://drive.google.com/file/d/1lGv1m4daz3wx9TI2QghwUxSkNv9pvyFF/view?usp=sharing"
                target="_blank"
              >
                {/* <span>DOWNLOAD OUR PORTFOLIO</span> */}
                DOWNLOAD OUR PORTFOLIO
              </a>
            </ul>
          </nav>
        </div>

        <div className={styles.Quote}>
          <span>
            'Commitment' - Something we deliver one project at a time.
            <br />
          </span>
          <strong>- Gawri Constructions</strong>

          <button className={styles.Button}>
            <Link to="/projects">
              Visit Our Completed Sites
              <img src={placeIcon} alt="" />
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Header;
