import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";
function Footer() {
  return (
    <footer className={styles.footer}>
      <section className={styles.privacy}>
        <Link to="/PrivacyPolicyandTOS" target="_blank">
          <span>PRIVACY POLICY</span>
        </Link>
        <Link to="/PrivacyPolicyandTOS" target="_blank">
          <span>TERMS OF SERVICE</span>
        </Link>
      </section>
      <section className={styles.logo}>
        <a href="https://www.facebook.com/thegreyyroom" target="_blank">
          <FacebookIcon
            style={{
              color: "#fff",
              height: "40px",
              width: "40px",
              padding: "10px",
              cursor: "pointer",
            }}
          />
        </a>
        <a href="https://www.instagram.com/thegreyyroom/?hl=en" target="_blank">
          <InstagramIcon
            style={{
              color: "#fff",
              height: "40px",
              width: "40px",
              padding: "10px",
              cursor: "pointer",
            }}
          />
        </a>
        <a
          href="https://www.linkedin.com/in/vishrut-gawri-733b46a8/"
          target="_blank"
        >
          <LinkedInIcon
            style={{
              color: "#fff",
              height: "40px",
              width: "40px",
              padding: "10px",
              cursor: "pointer",
            }}
          />
        </a>
        <a href="mailto:gcul@rediffmail.com">
          <EmailIcon
            style={{
              color: "#fff",
              height: "40px",
              width: "40px",
              padding: "10px",
              cursor: "pointer",
            }}
          />
        </a>
      </section>
      <section className={styles.copy}>
        <h5>© Copyright 2022 Gawri Constructions | All Rights Reserved</h5>
      </section>
    </footer>
  );
}

export default Footer;
