import React from "react";
import styles from "./whatweoffer.module.css";
function WhatWeOffer() {
  return (
    <section id="whatweoffer" className={styles.whyus}>
      <div className={styles.heading}>
        <h4>WHAT WE OFFER</h4>
      </div>
      <div className={styles.para}>
        <p>
          <strong>Gawri Constructions</strong> core activities are as follows-
          <ul>
            <li>Corporate architecture and interior designing</li>
            <li>Turnkey civil and interior services</li>
            <li>Project Management</li>
            <li>Boutique interiors for HNI villas</li>
          </ul>
        </p>
      </div>
    </section>
  );
}

export default WhatWeOffer;
