export const Projects = [
  {
    name: "United Nations International Children’s Emergency Fund",
    scope: "Civil Works",
    timeline: "2 Months",
    location: "Gandhi Nagar",
    imageUrl: require("../assets/Images/Projects/UNICEF.jpg"),
  },
  {
    name: "United Nations International Children’s Emergency Fund",
    scope: "Civil Works",
    timeline: "2 Months",
    location: "Ranchi",
    imageUrl: require("../assets/Images/Projects/UNICEF1.jpg"),
  },
  {
    name: "United Nations Development Programme",
    scope: "Interior Works",
    timeline: "2 Months",
    location: "Delhi NCR",
    imageUrl: require("../assets/Images/Projects/UNDP.png"),
  },
  {
    name: "Heritage Transport Trust Museum ",
    scope: "Civil Works",
    timeline: "10 Months",
    location: "Haryana",
    imageUrl: require("../assets/Images/Projects/HTTM.png"),
  },
  {
    name: "World Health Organization ",
    scope: "Interior Works",
    timeline: "3 Months",
    location: "Bangalore",
    imageUrl: require("../assets/Images/Projects/WHO.png"),
  },
  {
    name: "Luxury Farm House ",
    scope: "Interior and Civil Works",
    timeline: "6 Months",
    location: "Delhi NCR",
    imageUrl: require("../assets/Images/Projects/LFH.jpg"),
  },
  {
    name: "Luxury Villas ",
    scope: "Interior and Civil Works",
    timeline: "6 Months",
    location: "Delhi NCR",
    imageUrl: require("../assets/Images/Projects/LV.jpg"),
  },
  {
    name: "Indian Railway Welfare Organisation ",
    scope: "Civil Works",
    timeline: "15 Months",
    location: "Lucknow",
    imageUrl: require("../assets/Images/Projects/IRWO.png"),
  },
  {
    name: "Army Welfare Organisation ",
    scope: "Civil Works",
    timeline: "15 Months",
    location: "Goa",
    imageUrl: require("../assets/Images/Projects/AWO.png"),
  },
];
