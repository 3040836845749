import React from "react";
import Profile1 from "../assets/Images/profile1.jpg";
import styles from "./meetourteam.module.css";
import VISHRUT from '../assets/Images/Team/Vishrut.jpeg';
import VIPIN from '../assets/Images/Team/Vipin.jpeg';
function MeetOurTeam() {
  return (
    <section id="meetourteam" className={styles.whyus}>
      <div className={styles.heading}>
        <h4>MEET OUR TEAM</h4>
      </div>
      <div className={styles.team}>
        <div className={styles.prof_container}>
          <div className={styles.box}>
            <img className={styles.img_box} src={VIPIN} alt="profile-img" />
            <h2 className={styles.name}>Vipin Gawri</h2>
            <h3 className={styles.des}>Director</h3>
            <span className={styles.description}>
              A humble businessman with an experience of over 35 years in
              commercial contracting. He has delivered over 100 corporate
              projects in cities ranging from Delhi to Bangalore and is now
              focusing to cater only multinational corporates
            </span>
          </div>
        </div>
        <div className={styles.prof_container}>
          <div className={styles.box}>
            <img className={styles.img_box} src={VISHRUT} alt="profile-img" />
            <h2 className={styles.name}>Vishrut Gawri</h2>
            <h3 className={styles.des}>Lead Architect </h3>
            <span className={styles.description}>
              A young leader who has one objective- to make Gawri Constructions
              a name in the corporate design build industry. Having the
              opportunity to work with leading Project Management consultancies,
              he now drives business through his HNI network .
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MeetOurTeam;
