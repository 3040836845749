import { Grid, Paper } from "@mui/material";
import React from "react";
import styles from "./imagegrid.module.css";
import { styled } from "@mui/material/styles";
import HNI from "../assets/Images/ImageGrid/HNI.jpeg";
import Grid5 from "../assets/Images/ImageGrid/Grid5.jpeg";
import Grid4 from "../assets/Images/ImageGrid/Grid4.jpeg";
import Grid3 from "../assets/Images/ImageGrid/Grid3.jpeg";
import Grid2 from "../assets/Images/ImageGrid/Grid2.jpeg";
function ImageGrid() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "287px",
    width: "100%",
    overflow: "hidden",
    color: theme.palette.text.secondary,
  }));

  return (
    <section className={styles.ImageGrid}>
      <Grid container columns={{ xs: 1, sm: 2, md: 12 }}>
        <Grid item xs={1} sm={2} md={4}>
          <Item>
            <img
              height={200}
              src={Grid2}
              alt=""
              style={{ flexShrink: "0", minHeight: "100%", minWidth: "100%" }}
            />
          </Item>
        </Grid>
        <Grid item xs={1} sm={2} md={4}>
          <Item>
            <div className={styles.text}>
              <h2>Corporate Architecture and Interior Designing</h2>
              <span>
                Efficient space planning increase office floor efficiency and
                decrease your monthly capex outflow.
              </span>
            </div>
          </Item>
        </Grid>
        <Grid item xs={1} sm={2} md={4}>
          <Item>
            <img
              height={200}
              src={Grid3}
              alt=""
              style={{ flexShrink: "0", minHeight: "100%", minWidth: "100%" }}
            />
          </Item>
        </Grid>
        <Grid item xs={1} sm={2} md={4}>
          <Item>
            <div className={styles.text}>
              <h2>Turnkey Civil and Interior Services</h2>
              <span>
                One stop solution to design and execute your next commercial
                project. From designing floor plans to handing over the executed
                site.
              </span>
            </div>
          </Item>
        </Grid>
        <Grid item xs={1} sm={2} md={4}>
          <Item>
            <img
              height={200}
              src={Grid4}
              alt=""
              style={{ flexShrink: "0", minHeight: "100%", minWidth: "100%" }}
            />
          </Item>
        </Grid>
        <Grid item xs={1} sm={2} md={4}>
          <Item>
            <div className={styles.text}>
              <h2>Project Management</h2>
              <span>
                Our specialist project managers are located across India and
                have the technical knowledge, experience and market awareness to
                get the job done.{" "}
              </span>
            </div>
          </Item>
        </Grid>
        <Grid item xs={1} sm={2} md={4}>
          <Item>
            <img
              height={200}
              src={Grid5}
              alt=""
              style={{ flexShrink: "0", minHeight: "100%", minWidth: "100%" }}
            />
          </Item>
        </Grid>
        <Grid item xs={1} sm={2} md={4}>
          <Item>
            <div className={styles.text}>
              <h2>Boutique Interiors for HNI Villas </h2>
              <span>
                End to end services for our HNIs who wish to get their private
                estates designed
              </span>
            </div>
          </Item>
        </Grid>
        <Grid item xs={1} sm={2} md={4}>
          <Item>
            <img
              height={200}
              src={HNI}
              alt=""
              style={{ flexShrink: "0", minHeight: "100%", minWidth: "100%" }}
            />
          </Item>
        </Grid>
      </Grid>
    </section>
  );
}

export default ImageGrid;
